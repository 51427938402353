import React, { useCallback, useEffect, useMemo, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import AnalysisScreen from "../../../components/Interact/AnalysisScreen";
import RecordScreen from "../../../components/Interact/RecordScreen";
import VideoScreen from "../../../components/Interact/VideoScreen";
import { INTERACT_USER_EMAIL, INTERACT_USER_PASSWORD } from "../../../constants/env";
import {
  CREDENTIALS_EMBED_URL,
  CREDENTIALS_INTERACT_URL,
  LOGIN_URL,
  TOKEN_EMBED_URL,
    TOKEN_INTERACT_URL,
} from "../../../constants/urls";
import { useAuth } from "../../../contexts/authUser";
import { useFirebase } from "../../../contexts/firebase";
import { ifxApiClient } from "../../../helpers/api";
import { EMBED_STAGE, EmbedButton } from "./common";
import { matchInteractPath } from "./utils";

interface EmbedContentPageProps {
  interactionVideoUrl: string;
  expertVideoUrl: string;
  storyId: string;
  sceneId: string;
}
 
interface EmbedPageProps {
    setPreventSignIn: () => void;
    userId: string;
    interactUserId: string;
    isEmbedded?: boolean;
}
 
const EmbedContentPage = ({
    setPreventSignIn,
    userId,
  interactUserId,
  isEmbedded = false,
  interactionVideoUrl,
  expertVideoUrl,
  storyId,
  sceneId,
}: EmbedPageProps & EmbedContentPageProps) => {
  const [embedStage, setEmbedStage] = useState(EMBED_STAGE.INTERACTION_VIDEO);
  const [firebaseVideoUrl, setFirebaseVideoUrl] = useState<string | undefined>();
  const [analysisResponse, setAnalysisResponse] = useState<any>(null);
  const history = useHistory();
  const [isVideoPaused, setIsVideoPaused] = useState<boolean | undefined>();
  const firebase = useFirebase();

  useEffect(() => {
    // So the user gives webcam permission from the beginning, so when we reach the recording screen, it's already approved.
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        stream.getTracks().forEach(x => x.stop());
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

//   console.info("EmbedContentPage: isEmbedded, storyId, sceneId, interactUserId are ", isEmbedded, storyId, sceneId, interactUserId); // DEBUG
//   console.info("EmbedContentPage: interactionVideoUrl, expertVideoUrl are ", interactionVideoUrl, expertVideoUrl); // DEBUG
  const isLoading =
    embedStage === EMBED_STAGE.AUTHENTICATION || !interactionVideoUrl || !expertVideoUrl;

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "scroll",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "5px solid #bfefff",
        borderRadius: 10,
        padding: "20px",
      }}
    >
      {isLoading && <h5>Loading&hellip;</h5>}

      {embedStage === EMBED_STAGE.INTERACTION_VIDEO && interactionVideoUrl && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VideoScreen
            videoUrl={interactionVideoUrl}
            autoPlay
            paused={isVideoPaused}
            onVideoEndOrPaused={() => {
              setIsVideoPaused(true);
            }}
            onVideoPlay={() => {
              setIsVideoPaused(false);
            }}
          />

          <EmbedButton
            onClick={() => {
              setIsVideoPaused(prev => !prev);
            }}
          >
            {isVideoPaused ? "Listen" : "Pause"}
          </EmbedButton>

          <EmbedButton
            onClick={() => {
              setEmbedStage(EMBED_STAGE.RESPONSE_VIDEO_RECORDING);
            }}
          >
            Respond
          </EmbedButton>
        </div>
      )}
      {embedStage === EMBED_STAGE.EXPERT_VIDEO && expertVideoUrl && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5 style={{ display: "inline-block" }}>Expert video</h5>
          <VideoScreen videoUrl={expertVideoUrl} />
          <EmbedButton
            onClick={() => {
              setAnalysisResponse(null);
              setEmbedStage(EMBED_STAGE.INTERACTION_VIDEO);
            }}
          >
            Try again
          </EmbedButton>

          <EmbedButton
            onClick={() => {
              setEmbedStage(EMBED_STAGE.ANALYSIS);
            }}
          >
            Go back to feedback
          </EmbedButton>
        </div>
      )}

      {embedStage === EMBED_STAGE.RESPONSE_VIDEO_RECORDING && (
        <RecordScreen
          onAnalyze={videoUrl => {
            setFirebaseVideoUrl(videoUrl);
            setEmbedStage(EMBED_STAGE.ANALYSIS);
          }}
        />
      )}

      {embedStage === EMBED_STAGE.ANALYSIS && (
        <AnalysisScreen
          storyId={storyId}
          sceneId={sceneId}
          userId={userId}
          interactUserId={interactUserId}
          videoUrl={firebaseVideoUrl}
          onStageChange={async stage => {
            if (stage) {
              setEmbedStage(stage);
            } else {
              setPreventSignIn();
              await firebase.doSignOut();
              history.push(LOGIN_URL);
            }
          }}
          analysisResponse={analysisResponse}
          onAnalysisResponse={setAnalysisResponse}
          isEmbedded={isEmbedded}
        />
      )}
    </div>
  );
};

const EmbedContentPageWrapper = (props: EmbedPageProps) => {
  const [interactionVideoUrl, setInteractionVideoUrl] = useState<null | string>(null);
  const [expertVideoUrl, setExpertVideoUrl] = useState<null | string>(null);
  const [storyId, setStoryId] = useState<null | string>(null);
  const [sceneId, setSceneId] = useState<null | string>(null);
  const [isEmbedded, setIsEmbedded] = useState(false);
  const interactUserId = props.interactUserId;
  const firebase = useFirebase();
//   console.info("EmbedContentPageWrapper interactUserId is ",interactUserId); // DEBUG

  useEffect(() => {
    let match;
    let embedMatch;
    // console.info("EmbedContentPageWrapper first useEffect is parsing the URL for scene and story"); // DEBUG
    if (interactUserId === "") {
        embedMatch = matchPath<{ storyId: string; sceneId: string }>(
            window.location.pathname, {
                path: CREDENTIALS_EMBED_URL,
                exact: true,
                strict: false,
        });
        const interactMatch = matchPath<{ storyId: string; sceneId: string }>(
        window.location.pathname, {
            path: CREDENTIALS_INTERACT_URL,
            exact: true,
            strict: false,
        });
        match = embedMatch || interactMatch;
    } else {
        embedMatch = matchInteractPath<{ storyId: string; sceneId: string; userId: string }>(
            TOKEN_EMBED_URL
        );
        const interactUidMatch = matchInteractPath<{ storyId: string; sceneId: string; userId: string }>(
            TOKEN_INTERACT_URL
        );
        match = embedMatch || interactUidMatch;
    }

/*  the old code didn't handle the uid on the URL correctly
    const embedMatch = matchPath<{ storyId: string; sceneId: string }>(
        window.location.pathname, {
            path: CREDENTIALS_EMBED_URL,
            exact: true,
            strict: false,
    });
    const interactMatch = matchPath<{ storyId: string; sceneId: string }>(
      window.location.pathname, {
        path: CREDENTIALS_INTERACT_URL,
        exact: true,
        strict: false,
      });
    const match = embedMatch || interactMatch;
    */
    if (!!embedMatch) {
        // console.info("EmbedContentPageWrapper useEffect is setting isEmbedded to true"); // DEBUG
      setIsEmbedded(true);
    } else {
        // console.info("EmbedContentPageWrapper useEffect is setting isEmbedded to false"); // DEBUG
    }
    // console.info("EmbedContentPageWrapper useEffect is setting storyId and sceneId", match?.params.storyId, match?.params.sceneId); // DEBUG
    setStoryId(match?.params.storyId || null);
    setSceneId(match?.params.sceneId || null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  useEffect(() => {
    if (sceneId) {
      console.log("New EmbedContentPageWrapper is getting scene data for sceneId: ", sceneId);
      ifxApiClient
        // TODO: .get<ResponseShape> // what was mihai thinking??
        .get(`scenes/embed/${sceneId}`)
        .then(sceneResponse => {
          const data = sceneResponse.data as Record<string, unknown>;

          console.log(sceneId, data);

          const videos = data.videos as Record<string, string>;
          const interactionFileName =
            videos.interactionVideoName + "." + videos.interactionVideoType;
          firebase.storage.getDownloadURLForPath(interactionFileName).then(url => {
            setInteractionVideoUrl(url);
          });

          const expertFileName = videos.professionalVideoName + "." + videos.professionalVideoType;
          firebase.storage.getDownloadURLForPath(expertFileName).then(url => {
            setExpertVideoUrl(url);
          });
        })
        .catch(err => console.error(err));
    }
  }, [firebase.storage, sceneId]);

  if (interactionVideoUrl && expertVideoUrl && storyId && sceneId) {
    return (
      <EmbedContentPage
        {...props}
        interactionVideoUrl={interactionVideoUrl}
        expertVideoUrl={expertVideoUrl}
        storyId={storyId}
        sceneId={sceneId}
        isEmbedded={isEmbedded}
        interactUserId={interactUserId}
      />
    );
  }

  return <h5>Loading&hellip;</h5>;
};

const NewEmbedPageAuthWrapper = () => {
  const auth = useAuth();
  const [preventSignIn, setPreventSignIn] = useState(false);

  useEffect(() => {
    async function asyncLogIn() {
    //   console.log("Logging in INTERACT_USER_EMAIL: ", INTERACT_USER_EMAIL);
      await auth.signInWithEmailAndPassword(INTERACT_USER_EMAIL, INTERACT_USER_PASSWORD);
    }

    // TODO: remove when adding the new user role
    if (preventSignIn || !!auth.authUser) {
        // console.log("NewEmbedPageAuthWrapper Login failed with preventSignin, auth.authUser is ", preventSignIn,auth.authUser); // DEBUG
      // when exiting we call doSignOut, but right after this hook signs the user back in
      return;
    }

    asyncLogIn();
  }, [auth,preventSignIn]);

//   useEffect(() => {
  const embedUidMatch = matchInteractPath<{ storyId: string; sceneId: string; userId: string }>(
    TOKEN_EMBED_URL
  );
  const interactUidMatch = matchInteractPath<{ storyId: string; sceneId: string; userId: string }>(
    TOKEN_INTERACT_URL
  );
  const uidMatch = embedUidMatch || interactUidMatch;
//   console.info("NewEmbedPageAuthWrapper useEffect uidMatch.userID is ",uidMatch?.params.userId); // DEBUG
  const interactUserId = uidMatch?.params.userId || "";
//   console.info("NewEmbedPageAuthWrapper interactUserId is ",interactUserId); // DEBUG
  // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

  const contentBlock = auth.authUser ? (
    <EmbedContentPageWrapper
      userId={auth.authUser.uid}
      setPreventSignIn={() => setPreventSignIn(true)}
      interactUserId={interactUserId || ""}
    />
  ) : (
    <h5>Loading&hellip;</h5>
  );

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "scroll",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "5px solid #bfefff",
        borderRadius: 10,
        padding: "20px",
      }}
    >
      {contentBlock}
    </div>
  );
};

export default NewEmbedPageAuthWrapper;
